import React, { useState, useEffect } from 'react';
import Nav from './Nav';

function PomodoroTimer() {
  const [timeLeft, setTimeLeft] = useState(
    localStorage.getItem('timeLeft') ? Number(localStorage.getItem('timeLeft')) : 25 * 60
  );
  const [timerState, setTimerState] = useState(
    localStorage.getItem('timerState') ? localStorage.getItem('timerState') : 'work'
  );
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTimeLeft(prevTimeLeft => {
          if (prevTimeLeft === 0) {
            return timerState === 'work' ? 5 * 60 : 25 * 60;
          }
          return prevTimeLeft - 1;
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [timerState, isRunning]);

  useEffect(() => {
    localStorage.setItem('timerState', timerState);
    localStorage.setItem('timeLeft', timeLeft.toString());
  }, [timerState, timeLeft]);

  function handleStartStopClick() {
    setIsRunning(prevIsRunning => !prevIsRunning);
  }

  function handleWorkClick() {
    setIsRunning(false);
    setTimerState('work');
    setTimeLeft(25 * 60);
  }

  function handleBreakClick() {
    setIsRunning(false);
    setTimerState('break');
    setTimeLeft(5 * 60);
  }

  function formatTime(timeLeft) {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  return (
    <>
      <Nav></Nav>
      <div className='container' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: 40 }}>
        <h1 className='pomodoro-type'>{timerState === 'work' ? '💪 Makačka' : '🦦 Chill'}</h1>
        <h2 className='pomodoro-time'>{formatTime(timeLeft)}</h2>
        <div className='pomodoro-choice'>
          <button onClick={handleWorkClick} disabled={timerState === 'work'}>💪 Makačka</button>
          <button onClick={handleBreakClick} disabled={timerState === 'break'}>🦦 Chill</button>
        </div>
        <button className='pomodoro-start' onClick={handleStartStopClick}>{isRunning ? 'Stop' : 'Start'}</button>
      </div>
    </>
  );
}

export default PomodoroTimer;
