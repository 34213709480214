import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyA9VdbVvuysJfjhTj6c-jdFeSPEBG520ws",
    authDomain: "brain-2-4f8f6.firebaseapp.com",
    projectId: "brain-2-4f8f6",
    storageBucket: "brain-2-4f8f6.appspot.com",
    messagingSenderId: "741483025361",
    appId: "1:741483025361:web:f36af737fc688a17a5c6f0"
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();


export default firebase;
