import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import Todo from "./components/Todo";
import Login from "./components/Login";
import './App.css';
import Pomodoro from "./components/Pomodoro";

ReactDOM.render(
  <RecoilRoot>
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/todo" component={Todo} />
        <Route path="/pomodoro" component={Pomodoro} />
      </Switch>
    </Router>
  </RecoilRoot>,
  document.getElementById("root")
);
