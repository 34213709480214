import { atom, selector } from 'recoil';
import { db } from '../firebase';

export const todoListState = atom({
  key: 'todoListState',
  default: [],
});

export const filteredTodoListState = selector({
  key: 'filteredTodoListState',
  get: ({ get }) => {
    const todoList = get(todoListState);
    // TODO: Implement filter logic
    return todoList;
  },
});

export const addTodo = async (text) => {
  try {
    await db.ref('todos').push({
      text,
      completed: false,
    });
  } catch (error) {
    throw error;
  }
};

export const toggleTodo = async (todo) => {
  try {
    await db.ref(`todos/${todo.id}`).update({
      completed: !todo.completed,
    });
  } catch (error) {
    throw error;
  }
};

export const deleteTodo = async (todo) => {
  try {
    await db.ref(`todos/${todo.id}`).remove();
  } catch (error) {
    throw error;
  }
};
