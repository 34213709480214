import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { todoListState } from '../store/todo';
import { db } from '../firebase';
import Nav from './Nav';

const Todo = () => {
  const [inputValue, setInputValue] = useState('');
  const [importance, setImportance] = useState('1');
  const [todoList, setTodoList] = useRecoilState(todoListState);

  useEffect(() => {
    const todosRef = db.collection('todos');
    todosRef.orderBy('importance', 'desc').onSnapshot((snapshot) => {
      const todos = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTodoList(todos);
    });
  }, []);

  const addTodo = (e) => {
    e.preventDefault();
    const newTodo = {
      text: inputValue,
      isComplete: false,
      importance: importance,
    };
    const todosRef = db.collection('todos');
    todosRef.add(newTodo);
    setInputValue('');
    setImportance('1');
  };

  const updateTodo = (id, isComplete) => {
    const todoRef = db.collection('todos').doc(id);
    todoRef.update({ isComplete });
  };

  const deleteTodo = (id) => {
    const todoRef = db.collection('todos').doc(id);
    todoRef.delete();
  };

  const updateImportance = (id, importance) => {
    const todoRef = db.collection('todos').doc(id);
    todoRef.update({ importance });
  }

  return (
    <>
      <Nav />
      <div className='container'>
      <div className='zadani-wrapper'>
  <form onSubmit={addTodo}>
    <input type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} className='zadani' />
    <button type="submit">Přidat</button>
  </form>
  <div className="importance-buttons">
    <button className={importance === '1' ? 'active' : ''} onClick={() => setImportance('1')}>!</button>
    <button className={importance === '2' ? 'active' : ''} onClick={() => setImportance('2')}>!!</button>
    <button className={importance === '3' ? 'active' : ''} onClick={() => setImportance('3')}>!!!</button>
  </div>
</div>

        <ul>
          {todoList.map((todo) => (
            <li key={todo.id}>
              <input type="checkbox" checked={todo.isComplete} onChange={(e) => updateTodo(todo.id, e.target.checked)} />
              <span style={{ textDecoration: todo.isComplete ? 'line-through' : 'none' }}>{todo.text}</span>
              <div className="importance-buttons">
                <button className={todo.importance === '1' ? 'active' : ''} onClick={() => updateImportance(todo.id, '1')}>!</button>
                <button className={todo.importance === '2' ? 'active' : ''} onClick={() => updateImportance(todo.id, '2')}>!!</button>
                <button className={todo.importance === '3' ? 'active' : ''} onClick={() => updateImportance(todo.id, '3')}>!!!</button>
              </div>
              <button onClick={() => deleteTodo(todo.id)}>Smazat</button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Todo;
