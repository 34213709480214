import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const Nav = () => {
  return (
    <nav>

      <div className="nav-container">
        <div className="nav-logo">Brain 2.0</div>
        <ul className="nav-links">
          <li><Link to="/todo">Úkoly</Link></li>
          <li><Link to="/pomodoro">Pomodoro</Link></li>
        </ul>
        <div className="nav-user">
        <Link to="/"><span>Richard Mikeš</span></Link>
          <FontAwesomeIcon icon={faUser} />
        </div>
      </div>
    </nav>
  );
}

export default Nav;
