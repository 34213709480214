import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../firebase";


const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await auth.signInWithEmailAndPassword(email, password);
      history.push("/todo");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <form onSubmit={handleLogin} className="container login-block">
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button type="submit">Přihlásit se</button>
    </form>
  );
};

export default Login;
